import {
  ConfirmationResult, RecaptchaVerifier, UserCredential, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPhoneNumber, signInWithPopup,
} from 'firebase/auth';

import { FirebaseStatus } from '@/features/auth/enums';

import { auth, provider } from '@/utils/firebaseConfig';

// REGISTER
export const handleRegisterWithGoogle = async ():Promise<UserCredential | null | string> => {
  try {
    return await signInWithPopup(auth, provider);
  } catch (error: any) {
    if (error.code === FirebaseStatus.AUTH_POPUP_CLOSED_BY_USER) return FirebaseStatus.AUTH_POPUP_CLOSED_BY_USER;
    return null;
  }
};

export const handleRegisterWithEmailAndPassword = async (email: string, password: string):Promise<UserCredential | null | string> => {
  try {
    return await createUserWithEmailAndPassword(auth, email, password);
  } catch (error: any) {
    if (error.code === FirebaseStatus.AUTH_EMAIL_EXISTS) return FirebaseStatus.AUTH_EMAIL_EXISTS;
    return null;
  }
};

export const handlePhoneSendOpt = async (recaptcha: RecaptchaVerifier, phoneNumber: string): Promise<ConfirmationResult | null> => {
  try {
    return await signInWithPhoneNumber(auth, phoneNumber, recaptcha);
  } catch (error) {
    return null;
  }
};
// END REGISTER

// SIGNIN
export const handleSignInWithGoogle = async () => {
  try {
    return await signInWithPopup(auth, provider);
  } catch (error) {
    return null;
  }
};

export const handleSignInWithEmailAndPassword = async (email: string, password: string):Promise<UserCredential | null | string> => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res;
  } catch (error: any) {
    if (error.code === FirebaseStatus.AUTH_INVALID_CREDENTIALS) return FirebaseStatus.AUTH_INVALID_CREDENTIALS;
    return null;
  }
};
// END, SIGNIN
