import React, { useState, useEffect, useCallback } from 'react';

interface PasswordStrengthMeterProps {
  password: string;
}
const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({ password }) => {
  const [strength, setStrength] = useState<number>(0);
  const [criteria, setCriteria] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    specialChar: false,
  });

  const evaluatePasswordStrength = useCallback((pass: string): number => {
    let score = 0;
    if (!pass) {
      return score;
    }

    const lengthCriteria = pass.length >= 8;
    const upperCaseCriteria = /[A-Z]/.test(pass);
    const lowerCaseCriteria = /[a-z]/.test(pass);
    const numberCriteria = /\d/.test(pass);
    const specialCharCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(pass);

    setCriteria({
      length: lengthCriteria,
      upperCase: upperCaseCriteria,
      lowerCase: lowerCaseCriteria,
      number: numberCriteria,
      specialChar: specialCharCriteria,
    });

    const lengthScore = Math.min(20, pass.length * 2);
    const upperCaseScore = /[A-Z]/.test(pass) ? 10 : 0;
    const numbersScore = /\d/.test(pass) ? 10 : 0;
    const specialCharScore = /[!@#$%^&*(),.?":{}|<>]/.test(pass) ? 10 : 0;
    const lowerCaseScore = /[a-z]/.test(pass) ? 10 : 0;

    score = lengthScore + upperCaseScore + numbersScore + specialCharScore + lowerCaseScore;
    return score;
  }, []);

  useEffect(() => {
    setStrength(evaluatePasswordStrength(password));
  }, [password, evaluatePasswordStrength]);

  const getProgressColor = (score: number): string => {
    if (score < 20) return 'bg-red-500';
    if (score < 40) return 'bg-yellow-500';
    if (score < 60) return 'bg-yellow-400';
    return 'bg-green-500';
  };
  const createPassLabel = (score: number): string => {
    if (score < 20) return 'Very Weak';
    if (score < 40) return 'Weak';
    if (score < 60) return 'Fair';
    return 'Strong';
  };
  const progressBarStyle = {
    width: `${(Math.min(strength, 60) * 100) / 60}%`,
    heigth: '4px',
    borderRadius: '10px',
  };
  if (!password) {
    return null;
  }
  return (
    <div>
      <div className="w-full bg-gray-200 h-1.5">
        <div className={`h-1.5 ${getProgressColor(strength)}`} style={progressBarStyle} />
      </div>
      <p className="text-sm text-center mt-1 font-semibold">{createPassLabel(strength)}</p>
      <ul className="mt-2">
        <li className="flex items-center mt-1">
          {criteria.upperCase ? (
            <span className="text-green-500 text-xs mr-2 icon icon-check" />
          ) : (
            <span className="text-red-500 text-xs mr-2">❌</span>
          )}
          Una letra mayúscula
        </li>
        <li className="flex items-center mt-1">
          {criteria.lowerCase ? (
            <span className="text-green-500 text-xs mr-2 icon icon-check" />
          ) : (
            <span className="text-red-500 text-xs mr-2">❌</span>
          )}
          Una letra minúscula
        </li>
        <li className="flex items-center mt-1">
          {criteria.number ? (
            <span className="text-green-500 text-xs mr-2 icon icon-check" />
          ) : (
            <span className="text-red-500 text-xs mr-2">❌</span>
          )}
          Un número
        </li>
        <li className="flex items-center mt-1">
          {criteria.specialChar ? (
            <span className="text-green-500 text-xs mr-2 icon icon-check" />
          ) : (
            <span className="text-red-500 text-xs mr-2">❌</span>
          )}
          Un carácter especial
        </li>
        <li className="flex items-center mt-1">
          {criteria.length ? (
            <span className="text-green-500 text-xs mr-2 icon icon-check" />
          ) : (
            <span className="text-red-500 text-xs mr-2">❌</span>
          )}
          Al menos 8 caracteres
        </li>
      </ul>
    </div>
  );
};
export default PasswordStrengthMeter;
