import { create } from 'zustand';

interface UserUIState {
  isNotificationOpen: boolean;
  isSidebarOpen: boolean;
  toggleNotification: () => void;
  toggleSidebar: () => void;
  resetSidebar: () => void;
  isLoginModalOpen: boolean;
  toggleLoginModal: () => void;
  isRegisterModalOpen: boolean;
  toggleRegisterModal: () => void;
  isDepositModalOpen: boolean;
  toggleDepositModal: () => void;
  isDepositSheetOpen: boolean;
  toggleDepositSheet: () => void;
  setSelectedCategory: (category: string) => void;
  selectedCategory: string;
  isCheckboxChecked: boolean;
  toggleCheckbox: () => void;
  setIsAmountsLoaded: (isAmountsLoaded: boolean) => void;
  isAmountsLoaded: boolean;
  isOpenDeposit: boolean;
  setOpenDeposit: (isOpenDeposit: boolean) => void;
}

export const userUIStore = create<UserUIState>((set) => {
  return {
    isNotificationOpen: false,
    isSidebarOpen: false,
    isLoginModalOpen: false,
    isRegisterModalOpen: false,
    isDepositModalOpen: false,
    isDepositSheetOpen: false,
    selectedCategory: '',
    isCheckboxChecked: false,
    isAmountsLoaded: false,
    isOpenDeposit: false,
    toggleNotification: () => { return set((state) => { return { isNotificationOpen: !state.isNotificationOpen }; }); },
    toggleSidebar: () => { return set((state) => { return { isSidebarOpen: !state.isSidebarOpen }; }); },
    resetSidebar: () => { return set(() => { return { isSidebarOpen: true, isNotificationOpen: false }; }); },
    toggleLoginModal: () => { return set((state) => { return { isLoginModalOpen: !state.isLoginModalOpen }; }); },
    toggleRegisterModal: () => { return set((state) => { return { isRegisterModalOpen: !state.isRegisterModalOpen }; }); },
    toggleDepositModal: () => { return set((state) => { return { isDepositModalOpen: !state.isDepositModalOpen }; }); },
    toggleDepositSheet: () => { return set((state) => { return { isDepositSheetOpen: !state.isDepositSheetOpen }; }); },
    setSelectedCategory: (category: string) => { return set(() => { return { selectedCategory: category }; }); },
    toggleCheckbox: () => { return set((state) => { return { isCheckboxChecked: !state.isCheckboxChecked }; }); },
    setIsAmountsLoaded: (isAmountsLoaded: boolean) => { return set(() => { return { isAmountsLoaded }; }); },
    setOpenDeposit: (isOpenDeposit: boolean) => { return set(() => { return { isOpenDeposit }; }); },
  };
});
